import * as React from "react";
import { Link } from "gatsby";

import BrowserMockup from "../components/browserMockup";
import Layout from "../components/layout";
import Seo from "../components/seo";

import clarityScreenshot from "../images/clarityScreenshot.png";
import gladstoneScreenshot from "../images/gladstoneScreenshot.png";
import blogScreenshot from "../images/blogScreenshot.png";

import htmlLogo from "../images/html5.svg";
import webpackLogo from "../images/webpack.svg";
import awsLogo from "../images/aws.png";
import gcpLogo from "../images/gcp.png";
import graphQLLogo from "../images/graphql.png";
import reactLogo from "../images/react.svg";
import es6Logo from "../images/es6.png";

const logoStyle = { maxWidth: "100%", paddingLeft: 30, paddingRight: 30 };

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi</h1>
    <p>
      I am a self-motivated full stack developer based in Melbourne, Australia.
    </p>
    <p>
      I have a strong knowledge of modern web technologies, product development
      and machine learning. I have extensive experience building SAAS platforms,
      and working in agile startup environments.
    </p>

    <h3 style={{ marginTop: "1rem" }}>Projects</h3>

    <div style={{ display: "flex", marginLeft: -10, marginRight: -10 }}>
      <div style={{ flex: 1, padding: 10 }}>
        <Link to="/portfolio/clarity/">
          <BrowserMockup screenshot={clarityScreenshot} />
        </Link>
        <h4>Clarity Coach</h4>
        <p>
          Clarity Coach is a startup I founded which uses Machine Learning to
          analyse eSport matches. <Link to="/portfolio/clarity/">More.</Link>
        </p>
      </div>
      <div style={{ flex: 1, padding: 10 }}>
        <Link to="/portfolio/design/">
          <BrowserMockup screenshot={gladstoneScreenshot} />
        </Link>
        <h4>Website Design</h4>
        <p>
          Freelance website design and development I've done for various
          clients. <Link to="/portfolio/design/">More.</Link>
        </p>
      </div>
      <div style={{ flex: 1, padding: 10 }}>
        <Link to="/code">
          <BrowserMockup screenshot={blogScreenshot} />
        </Link>
        <h4>Code Blog</h4>
        <p>
          A collection of writings about code, design patterns and data
          structures I've come across. <Link to="/code">More.</Link>
        </p>
      </div>
    </div>

    <h3 style={{ marginTop: "1rem" }}>Skills</h3>

    <p>
      I am a full stack web developer recently focusing on React, Node and
      GraphQL. <Link to="/resume">More.</Link>
    </p>

    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <img src={htmlLogo} style={logoStyle} />
      </div>
      <div style={{ flex: 1 }}>
        <img src={es6Logo} style={logoStyle} />
      </div>
      <div style={{ flex: 1 }}>
        <img
          src={reactLogo}
          style={Object.assign({ paddingTop: 7 }, logoStyle)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <img src={graphQLLogo} style={logoStyle} />
      </div>
      <div style={{ flex: 1 }}>
        <img src={webpackLogo} style={logoStyle} />
      </div>
      <div style={{ flex: 1 }}>
        <img
          src={awsLogo}
          style={Object.assign({ paddingTop: 25 }, logoStyle)}
        />
      </div>
    </div>

    <h3 style={{ marginTop: "1rem" }}>Certifications</h3>

    <div style={{ display: "flex" }}>
      <img src={awsLogo} style={{ height: 50, paddingRight: 15 }} />
      <div>
        <b>AWS Certified Solutions Architect</b>
        <p>
          An AWS exam about architecting and deploying secure, robust and
          scalable applications.
        </p>
      </div>
    </div>

    <div style={{ display: "flex" }}>
      <img
        src={gcpLogo}
        style={{ height: 50, paddingLeft: 10, paddingRight: 18 }}
      />
      <div>
        <b>Professional Data Engineer</b>
        <p>
          A Google qualification about the design of data processing and machine
          learning systems.
        </p>
      </div>
    </div>

    <h3 style={{ marginTop: "1rem" }}>Contact</h3>

    <p>The best way to contact me is through email.</p>
    <p>
      <b style={{ display: "inline-block", width: 100 }}>Email</b>
      <span>contact@davidcook.io</span>
    </p>
    <p>
      <b style={{ display: "inline-block", width: 100 }}>Mobile</b>
      <span>0409661132</span>
    </p>
  </Layout>
);

export default IndexPage;
