import React from "react";

import "./browserMockup.css";

export default ({ screenshot, mobile }) => {
  return (
    <div className={mobile ? "mobile-mockup" : "browser-mockup"}>
      <img
        style={
          mobile
            ? { maxWidth: "100%", width: 294, height: 147 }
            : { maxWidth: "100%", width: 294, height: 147 }
        }
        src={screenshot}
      />
    </div>
  );
};
